
import { defineComponent, reactive, ref, watch } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { getFacilityBySpace } from "@/core/services/api/facilities";
import localstorage from "@/core/services/LocalstorageService";
import { getSupportedTaxes } from "@/core/services/api/taxes";
import { useRoute } from "vue-router";
import { getTargetChargePlan, updateFacilityChargePlan } from "@/core/services/api/chargePlan";
import {
  getUtcTimeString,
  getShiftBeginTimeString,
  getShiftEndTimeString,
  getDefaultTimeString,
  getDefaultEndTimeString
} from "@/core/utils/timeString";
import moment from "moment";

export default defineComponent({
  setup() {
    const route = useRoute();
    const chargePlanGuid = route.params.chargePlanGuid;
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const basicSetUpRef = ref<null | HTMLFormElement>(null);
    const purchaseSetUpRef = ref<null | HTMLFormElement>(null);
    const useSetUpForm = ref<null | HTMLFormElement>(null);
    const basicData = reactive({
      title: "",
      facility: "",
      timeMode: "Minutes",
      reservationMode: "Repeated",
      description: "",
      usageNotice: "",
      beginTime: new Date(),
      endTimeRadio: "notLimit",
      endTime: new Date(),
    });
    const buySetting = reactive({
      priceTag: "",
      currency: "TWD",
      chargeTimeRadio: "notLimit",
      chargeTime: 0,
      amount: 10,
      repeatedTime: 30,
      tax: "b98A6iqKXk-_sSFXFYoCKQ", //預設消費稅guid
      minReservationTimeRadio: "notLimit",
      minReservationTime: 30,
      maxReservationTimeRadio: "notLimit",
      maxReservationTime: -1,
      minQuota: 1,
      maxQuotaRadio: "notLimit",
      maxQuota: -1,
      isShift: false,
      localBeginTime: 0,
      localEndTime: 0,
    });
    const useSetting = reactive({
      capacity: 1,
      availableDays: 30,
      minuteToEnter: 10,
      minuteToCancel: 30,
      minuteToCheckOut: 15,
      reserveBeforeBeginTime: 0,
      reserveAfterEndTime: 0,
      autoRelease: false,
      canDoorAccess: true,
      canIotFetch: true,
      canDistribute: true,
      canInvite: false,
      canUserCheckOut: true,
      canUserCheckIn: true,
      canUserChangeSeat: true,
      canUserFeedback: false,
      feedbackBeginTime: "",
      feedbackDuration: 20,
    });

    const verifyMinReservationTime = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("請輸入最小購買時間"));
      } else if (value < buySetting.chargeTime) {
        return callback(new Error("最小購買時間必須大於計價時間單位"));
      } else if (value % buySetting.chargeTime !== 0) {
        return callback(new Error("最小購買時間必須是計價時間單位的倍數"));
      } else {
        callback();
      }
    };
    const verifyMaxReservationTime = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("請輸入最大購買時間"));
      } else if (value == -1) {
        callback();
      } else if (value < buySetting.chargeTime) {
        return callback(new Error("最大購買時間必須大於或等於最小購買時間"));
      } else if (value < buySetting.minReservationTime) {
        return callback(new Error("最大購買時間必須大於計價時間單位"));
      } else if (value % buySetting.chargeTime !== 0) {
        return callback(new Error("最大購買時間必須是計價時間單位的倍數"));
      } else {
        callback();
      }
    };
    const rules = reactive({
      title: [
        {
          required: true,
          message: "請輸入價格方案名稱",
          trigger: "blur",
        },
      ],
      facility: [
        {
          required: true,
          message: "請選擇設施",
          trigger: "change",
        },
      ],
      minReservationTime: [
        { validator: verifyMinReservationTime, trigger: "change" },
      ],
      maxReservationTime: [
        { validator: verifyMaxReservationTime, trigger: "change" },
      ],
    });
    const facilityOption: any = reactive([]);
    const facilityTypeMap: any = reactive({
      Facility: "設施",
      Property: "設備",
    });
    // 計價時間單位選單
    const chargeTimeOption: any = reactive([]);
    //貨幣單位選單
    const currencyOption = reactive([
      { label: "免費", value: "Free" },
      { label: "點數", value: "Point" },
      { label: "台幣", value: "TWD" },
    ]);
    // 稅選單
    const taxOption: any = reactive([]);
    const timeModeUnitMap = {
      Minutes: "分鐘",
      Days: "天",
    };
    const currencyMap = {
      TWD: "台幣",
      Point: "點數",
    };
    const minReservationTimeOption: any = reactive([]);
    const minutesTimeOption = [
      { label: "30分鐘", value: 30 },
      { label: "60分鐘", value: 60 },
      { label: "兩小時", value: 120 },
      { label: "三小時", value: 180 },
      { label: "四小時", value: 240 },
    ];
    const daysTimeOption = [
      { label: "一小時", value: 1 },
      { label: "二小時", value: 2 },
      { label: "三小時", value: 3 },
      { label: "四小時", value: 4 },
      { label: "五小時", value: 5 },
    ];
    const repeatedTimeOption = [
      { label: "15分鐘", value: 15 },
      { label: "30分鐘", value: 30 },
      { label: "一小時", value: 60 },
    ];
    const formValidateObj = reactive({
      basicSetUp: false,
      purchaseSetUp: false,
      useSetUp: false,
    });
    const formValidateObjMap = reactive({
      basicSetUp: 1,
      purchaseSetUp: 2,
      useSetUp: 3,
    });
    const chargePlan: any = reactive({});

    // tab顯示驗證未過頁面
    const showNotPassTab = (tab) => {
      let tab1 = document.getElementById("addChargePlanTab_1");
      let tab2 = document.getElementById("addChargePlanTab_2");
      let tab3 = document.getElementById("addChargePlanTab_3");
      let tabA1 = document.getElementById("addChargePlanTab_a1");
      let tabA2 = document.getElementById("addChargePlanTab_a2");
      let tabA3 = document.getElementById("addChargePlanTab_a3");
      tab1?.classList.remove("active", "show");
      tab2?.classList.remove("active", "show");
      tab3?.classList.remove("active", "show");
      tabA1?.classList.remove("active");
      tabA2?.classList.remove("active");
      tabA3?.classList.remove("active");
      switch (tab) {
        case 1:
          tab1?.classList.add("active", "show");
          tabA1?.classList.add("active");
          break;
        case 2:
          tab2?.classList.add("active", "show");
          tabA2?.classList.add("active");
          break;
        case 3:
          tab3?.classList.add("active", "show");
          tabA3?.classList.add("active");
          break;
      }
    };

    const onSubmit = async () => {
      basicSetUpRef.value!.validate((valid) => {
        formValidateObj.basicSetUp = valid;
      });
      purchaseSetUpRef.value!.validate((valid) => {
        formValidateObj.purchaseSetUp = valid;
      });
      useSetUpForm.value!.validate((valid) => {
        formValidateObj.useSetUp = valid;
      });

      let allPass = true;
      for (const key in formValidateObj) {
        if (!formValidateObj[key]) {
          allPass = false;
          showNotPassTab(formValidateObjMap[key]);
          return;
        }
      }
      if (allPass) {
        console.log("buySetting.localBeginTime", buySetting.localBeginTime);
        console.log("buySetting.localEndTime", buySetting.localEndTime);
        await Swal.fire({
          title: "您確定要更新方案嗎?",
          confirmButtonText: "確定",
          showCancelButton: true,
          cancelButtonText: `取消`,
        }).then(async (result) => {
          if (result.isConfirmed) {
            let includeTax =
              buySetting.currency == "TWD" &&
              buySetting.tax == "b98A6iqKXk-_sSFXFYoCKQ";
            let timeChargePlanBasicData = {
              title: basicData.title,
              description: basicData.description,
              timeMode: basicData.timeMode,
              reservationMode: basicData.reservationMode,
              beginTime: getUtcTimeString(basicData.beginTime),
              endTime:  basicData.endTimeRadio=="notLimit"?getDefaultEndTimeString():getUtcTimeString(basicData.endTime),
              usageNotice: basicData.usageNotice,
              priceTag: buySetting.priceTag,
              chargeTime: buySetting.chargeTime,
              currency: buySetting.currency,
              amount: buySetting.amount,
              repeatedTime: buySetting.repeatedTime,
              tax: includeTax ? buySetting.tax : "",
              includeTax: includeTax,
              minReservationTime: buySetting.minReservationTime,
              maxReservationTime: buySetting.maxReservationTime,
              minQuota: buySetting.minQuota,
              maxQuota: buySetting.maxQuota,
              reservationSetting: {
                isShift: buySetting.isShift,
                localBeginTime: getShiftBeginTimeString(
                  buySetting.localBeginTime
                ),
                localEndTime: getShiftEndTimeString(buySetting.localEndTime),
                reserveBeforeBeginTime: useSetting.reserveBeforeBeginTime,
                reserveAfterEndTime: useSetting.reserveAfterEndTime,
              },
              setting: {
                invitationNumber: useSetting.capacity - 1,
                availableDays: useSetting.availableDays,
                minuteToEnter: useSetting.minuteToEnter,
                minuteToCancel: useSetting.minuteToCancel,
                minuteToCheckOut: useSetting.minuteToCheckOut,
                autoRelease: useSetting.autoRelease,
                canDoorAccess: useSetting.canDoorAccess,
                canIotFetch: useSetting.canIotFetch,
                canDistribute: useSetting.canDistribute,
                canInvite: useSetting.canInvite,
                canUserCheckOut: useSetting.canUserCheckOut,
                canUserCheckIn: useSetting.canUserCheckIn,
                canUserChangeSeat: useSetting.canUserChangeSeat,
                canUserFeedback: useSetting.canUserFeedback,
                feedbackBeginTime:
                  useSetting.feedbackBeginTime == ""
                    ? getDefaultTimeString()
                    : getUtcTimeString(useSetting.feedbackBeginTime),
                feedbackDuration: useSetting.feedbackDuration,
              },
            };

            console.log("timeChargePlanBasicData", timeChargePlanBasicData);
            updateFacilityChargePlan(basicData.facility,chargePlan.guid, timeChargePlanBasicData).then(()=>init())
          }
        });
      }
    };

    // 設置所有設施
    const setFacilityOption = async () => {
      let facilities = await getFacilityBySpace(currentSpaceGuid!);
      console.log("facilities", facilities);
      for (const item of facilities) {
        facilityOption.push({
          name: `${facilityTypeMap[item.facility.type]} : ${
            item.facility.name
          }`,
          value: item.facility.guid,
          type: item.facility.type,
        });
      }
      facilityOption.splice(
        0,
        facilityOption.length,
        ...facilityOption.sort((a, b) => (a.type == "Facility" ? 1 : -1))
      );
    };

    // 設置稅選項
    const setTaxOption = async () => {
      taxOption.splice(0);
      let supportedTaxes = await getSupportedTaxes();
      taxOption.push({ value: "noTax", label: "不需稅率" });
      supportedTaxes.forEach((taxe) => {
        taxOption.push({ value: taxe.guid, label: taxe.description });
      });
    };

    // 判斷是否顯示欄位
    const isItemShow = (item) => {
      // console.log("basicData", basicData);
      switch (item) {
        case "chargeTime":
          return buySetting.currency !== "Free";
        case "amount":
          return buySetting.currency !== "Free";
        case "tax":
          return (
            buySetting.currency !== "Free" && buySetting.currency !== "Point"
          );
        case "repeatedTime":
        case "reservationMode":
          return basicData.timeMode == "Minutes";
      }
    };

    watch(
      () => basicData.timeMode,
      () => {
        switch (basicData.timeMode) {
          case "Minutes":
            chargeTimeOption.splice(
              0,
              chargeTimeOption.length,
              ...minutesTimeOption
            );
            buySetting.chargeTime = 30;
            break;
          case "Days":
            chargeTimeOption.splice(
              0,
              chargeTimeOption.length,
              ...daysTimeOption
            );
            buySetting.chargeTimeRadio = "limit";
            buySetting.minReservationTimeRadio = "limit";
            buySetting.chargeTime = 1;
            basicData.reservationMode = "Fixed";
            buySetting.repeatedTime = 0;
            break;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    // 設定最小購買時間選單
    const setMinReservationTimeOption = () => {
      switch (basicData.timeMode) {
        case "Minutes":
          minReservationTimeOption.splice(
            0,
            minReservationTimeOption.length,
            ...minutesTimeOption.filter((o) => o.value >= buySetting.chargeTime)
          );
          buySetting.minReservationTime = minReservationTimeOption[0].value;
          break;
        case "Days":
          minReservationTimeOption.splice(
            0,
            minReservationTimeOption.length,
            ...daysTimeOption.filter((o) => o.value >= buySetting.chargeTime)
          );
          buySetting.minReservationTime = minReservationTimeOption[0].value;
          break;
      }
    };

    watch(
      () => buySetting.chargeTime,
      () => {
        setMinReservationTimeOption();
      },
      {
        immediate: true,
      }
    );
    // 設定最大購買時間選單
    const setMaxReservationTime = () => {
      if (buySetting.maxReservationTimeRadio == "notLimit") {
        buySetting.maxReservationTime = -1;
      }
    };
    watch(
      () => buySetting.maxReservationTimeRadio,
      () => {
        setMaxReservationTime();
      },
      {
        immediate: true,
      }
    );
    // 設定金額
    const setAmount = () => {
      if (buySetting.currency == "Free") {
        buySetting.amount = 0;
      }
    };
    watch(
      () => buySetting.currency,
      () => {
        setAmount();
      },
      {
        immediate: true,
      }
    );
    const makeRange = (start: number, end: number) => {
      const result: number[] = [];
      for (let i = start; i <= end; i++) {
        result.push(i);
      }
      return result;
    };

    // 設置入住小時選單
    const disabledLocalBeginTimeHoursOption = () => {
      return makeRange(0, 11);
    };

    // 設置入住分鐘選單
    const disabledLocalBeginTimeMinutesOption = (hour) => {
      return [];
    };

    // 設置秒選單
    const disabledLocalTimeSecondsOption = () => {
      return makeRange(1, 59);
    };

    // 設置翌日小時選單
    const disabledLocalEndTimeHoursOption = () => {
      return makeRange(13, 23);
    };

    // 設置翌日分鐘選單
    const disabledLocalEndTimeMinutesOption = (hour) => {
      if (hour === 12) {
        return makeRange(1, 59);
      }
      return [];
    };

    const setChargePlan = async () => {
      let request = await getTargetChargePlan(chargePlanGuid);
      Object.assign(chargePlan, request);
      console.log("chargePlan", chargePlan);
      // 設定基本資料
      Object.assign(basicData, {
        title: chargePlan.title,
        facility: chargePlan.target,
        timeMode: chargePlan.timeMode,
        reservationMode: chargePlan.reservationMode,
        description: chargePlan.description,
        usageNotice: chargePlan.usageNotice,
        beginTime: moment(chargePlan.beginTime),
        endTimeRadio:
          chargePlan.endTime == "9999-12-31T23:59:59.000Z"
            ? "notLimit"
            : "limit",
        endTime: moment(chargePlan.endTime),
      });
      console.log("basicData", basicData);

      // 設定購買設定
      Object.assign(buySetting, {
        priceTag: chargePlan.priceTag,
        currency: chargePlan.currency,
        chargeTimeRadio: chargePlan.timeMode == "Days" ? "limit" : "notLimit",
        chargeTime: chargePlan.chargeTime,
        amount: chargePlan.amount,
        repeatedTime: chargePlan.repeatTime,
        tax: chargePlan.tax,
        minReservationTimeRadio:
          chargePlan.timeMode == "Days" ? "limit" : "notLimit",
        minReservationTime: chargePlan.minReservationTime,
        maxReservationTimeRadio:
          chargePlan.maxReservationTime == -1 ? "notLimit" : "limit",
        maxReservationTime: chargePlan.maxReservationTime,
        minQuota: chargePlan.minQuota,
        maxQuotaRadio: chargePlan.maxQuota == -1 ? "notLimit" : "limit",
        maxQuota: chargePlan.maxQuota,
        isShift: chargePlan.reservationSetting.isShift,
        localBeginTime:
          chargePlan.reservationSetting.localBeginTime ==
          "0001-01-01T08:00:00.000Z"
            ? 0
            : moment(chargePlan.reservationSetting.localBeginTime),
        localEndTime:
          chargePlan.reservationSetting.localEndTime ==
          "0001-01-01T08:00:00.000Z"
            ? 0
            : moment(chargePlan.reservationSetting.localEndTime),
      });
      console.log("buySetting", buySetting);
      // 設定使用設定
      Object.assign(useSetting, {
        capacity: chargePlan.setting.invitationNumber + 1,
        availableDays: chargePlan.setting.availableDays,
        minuteToEnter: chargePlan.setting.minuteToEnter,
        minuteToCancel: chargePlan.setting.minuteToCancel,
        minuteToCheckOut: chargePlan.setting.minuteToCheckOut,
        reserveBeforeBeginTime:
          chargePlan.reservationSetting.reserveBeforeBeginTime,
        reserveAfterEndTime: chargePlan.reservationSetting.reserveAfterEndTime,
        autoRelease: chargePlan.setting.autoRelease,
        canDoorAccess: chargePlan.setting.canDoorAccess,
        canIotFetch: chargePlan.setting.canIotFetch,
        canDistribute: chargePlan.setting.canDistribute,
        canInvite: chargePlan.setting.canInvite,
        canUserCheckOut: chargePlan.setting.canUserCheckOut,
        canUserCheckIn: chargePlan.setting.canUserCheckIn,
        canUserChangeSeat: chargePlan.setting.canUserChangeSeat,
        canUserFeedback: chargePlan.setting.canUserFeedback,
        feedbackBeginTime:
          chargePlan.setting.feedbackBeginTime == "0001-01-01T00:00:00.000Z"
            ? ""
            : chargePlan.setting.feedbackBeginTime,
        feedbackDuration: chargePlan.setting.feedbackDuration,
      });
      console.log("useSetting", useSetting);
    };

    const init = async () => {
      await setChargePlan();

      await setFacilityOption();
      await setTaxOption();
    };
    init();

    return {
      basicSetUpRef,
      purchaseSetUpRef,
      useSetUpForm,
      basicData,
      buySetting,
      useSetting,
      onSubmit,
      rules,
      facilityOption,
      chargeTimeOption,
      currencyOption,
      taxOption,
      isItemShow,
      timeModeUnitMap,
      currencyMap,
      minReservationTimeOption,
      repeatedTimeOption,
      disabledLocalBeginTimeHoursOption,
      disabledLocalBeginTimeMinutesOption,
      disabledLocalTimeSecondsOption,
      disabledLocalEndTimeHoursOption,
      disabledLocalEndTimeMinutesOption,
    };
  },
});
